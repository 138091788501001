<template>
  <div
    class="flex items-center justify-center w-full px-4 py-6"
    :class="[backgroundColor]"
  >
    <div
      class="
        flex
        items-center
        justify-between
        w-full
        max-w-screen-lg
        gap-4
        lg:px-10
      "
    >
      <Usp
        v-for="(usp, uspIndex) in allUsps"
        :key="'usp-' + uspIndex"
        :class="[iconColor, textColor]"
        :upload-icon="usp.uploadIcon"
        :title="usp.title"
        :text="usp.text"
      />
    </div>
  </div>
</template>

<script>
import Usp from '~/components/usp/Usp'

export default {
  name: 'UspContainer',
  components: {
    Usp,
  },
  props: {
    allUsps: {
      type: Array,
      required: true,
    },
    mobileColumns: {
      type: String,
      required: false,
      default: '3',
    },
    desktopColumns: {
      type: String,
      required: false,
      default: '3',
    },
    backgroundColor: {
      type: String,
      required: false,
      default: 'bg-black',
    },
    iconColor: {
      type: String,
      required: false,
      default: 'fill-white',
    },
    textColor: {
      type: String,
      required: false,
      default: 'text-white',
    },
  },
}
</script>
