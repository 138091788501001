<template>
  <div class="flex flex-col items-center gap-[10px] lg:gap-6 lg:flex-row">
    <img
      v-if="iconSrc"
      class="w-6 h-6 lg:w-8 lg:h-8"
      :src="iconSrc"
      :alt="iconAlt"
      loading="lazy"
    />
    <div class="flex flex-col gap-[2px]">
      <span
        class="
          text-xs
          font-semibold
          tracking-wider
          text-center
          uppercase
          lg:text-sm lg:text-left
        "
        >{{ title }}</span
      >
      <span
        class="text-xs text-center lg:text-sm lg:text-left tracking-wider"
        >{{ text }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'Usp',
  props: {
    uploadIcon: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    text: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    iconSrc() {
      return this.uploadIcon?.filename
    },
    iconAlt() {
      return this.uploadIcon?.alt
    },
  },
}
</script>
